// 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./assets/sass/style.react.scss";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.ant-select {
  color: #fff !important;

  .ant-select-selector {
    height: 100% !important;
    color: #ffffff !important;
    background-color: #3699ff !important;
    border-color: #3699ff !important;
    display: flex !important;
    align-items: center !important;
    .ant-select-selection-placeholder {
      color: #fff !important;
    }
  }
  .ant-select-arrow,
  .ant-select-selection-item {
    color: #fff !important;
  }
}

.css-e08w8w-MuiButtonBase-root,
.css-1kopazz {
  background-color: #1976d2 !important;
}
.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
    white-space: nowrap;
    min-width: 100px;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }

  .table.table-head-custom thead th {
    font-weight: 600;
    color: #070707f1 !important;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.top-select {
  min-width: 75px;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}

.action-btn {
  background-color: #009688;
}

.action-btn svg rect {
  fill: white;
}

.navigation-icon svg,
.action-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-white path {
  fill: white;
}

.upload-image button:hover {
  background-color: transparent;
}

.ql-container {
  height: 12rem !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.logo {
  width: 15rem;
  height: auto;
}

.image-add-icon {
  transform: scale(4);
  padding: 0 !important;
  margin: 1rem !important;
}

.row-hover tbody tr:hover {
  background-color: #e9e9e9;
  color: black;
  cursor: pointer;
}

.delete-image-btn {
  right: 0;
  top: 0;
  background-color: #c6c6c6 !important;
  color: red !important;
  padding: 4px !important;
  border-radius: 4px !important;

  & svg {
    font-size: 2.25rem;
  }
}

.fit-content {
  width: fit-content !important;
}

.delete-action-btn {
  color: red !important;
  padding: 4px !important;
  border-radius: 4px !important;

  &:hover {
    background-color: white !important;
  }

  & svg {
    font-size: 2.25rem;
  }
}

.edit-action-btn {
  color: gray !important;
  padding: 4px !important;
  border-radius: 2px !important;

  &:hover {
    background-color: white !important;
  }

  & svg {
    font-size: 2rem;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.filter-select {
  .select__control {
    min-height: 38.39px;
    max-height: 38.39px;
  }

  // .select__indicator {
  //   padding: 6px;
  //   margin-top: 2px;
  // }

  // .select__indicator-separator {
  //   margin-top: 5px;
  // }

  .select__value-container {
    margin-top: 2px;
  }
}

.ck {
  height: 250px;
}

.width-5 {
  width: 8%;
}

.location {
  text-decoration: underline;
}

.react-date-picker {
  width: 320px !important;
}

#external-events {
  padding: 0 10px;
  border: 1px solid #ccc;
  background: #eee;
}

#external-events .fc-event {
  cursor: move;
  margin: 3px 0;
}

#external-events .fc-daygrid-event {
  white-space: normal;
}

#external-events .fc-daygrid-event {
  white-space: normal;
}

#calendar-container {
  z-index: 1;
}

#calendar {
  max-width: 1100px;
}

#calendar .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
}

.button-yellow {
  background-color: #cab91f !important;
}

:root {
  .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-base-border);
  }
}

.icons-container {
  height: 100%;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.icons-container .boxes {
  margin: auto;
  padding: 10px;
}

/*Checkboxes styles*/
.icons-container input[type="checkbox"] {
  display: none;
}

.icons-container input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  color: #ddd;
  cursor: pointer;
}

.icons-container input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}
.schedule-site-select {
  width: 200px !important;
  z-index: 11 !important;
}
.icons-container input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 4px solid #6cc0e5;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

.icons-container input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.google-toll-status-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}

.gt-status-txt {
  cursor: pointer;
}

.gauto-status-tooltip {
  padding: 8px;
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 12px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .issue-text {
    font-size: 14px;
    text-align: justify;
  }
}

// GREEN - #05ac5b
// YELLOW - #f9aa01
// RED - #d70000
.rmdp-container {
  width: 100% !important;
}

.aside-minimize:not(.aside-minimize-hover) .sidebar_site_list_menu {
  display: none !important;
}

.MuiDialog-container {
  .MuiPaper-root {
    .MuiBox-root {
      @media (max-width: 425px) {
        max-width: 320px !important;
      }

      @media (max-width: 375px) {
        max-width: 310px !important;
      }

      @media (max-width: 330px) {
        max-width: 250px !important;
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.seat_color_tag {
  width: 10px;
  min-width: 10px !important;
  height: 10px;
  border-radius: 2px;
  margin: auto 0;
}
